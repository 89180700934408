import { STATUSES_TIMEOUT } from '@modules/gpdip/duck/gpdipConstants';
import { useLazyGpdipRunStatusQuery } from '@modules/gpdip/duck/gpdipApi';
import { RunStatus } from '@modules/gpdip/GpdipTypes';
import { useEffect, useState } from 'react';

export const useStatusListener = () => {
  const [runStatusQuery, { isLoading }] = useLazyGpdipRunStatusQuery();
  const [statuses, setStatuses] = useState<RunStatus[]>([]);
  const refStatusesState: { intervalId: null | NodeJS.Timer; lastFetch: number | null } = {
    intervalId: null,
    lastFetch: null,
  };

  let isFetchStatuses = false;

  const getStatuses = async () => {
    try {
      const data = await runStatusQuery().unwrap();
      setStatuses(data);
    } catch (err) {
      console.error(err);
    }
  };

  const currentFetchGpdipStatuses = () => {
    if (isFetchStatuses) {
      return;
    }
    refStatusesState.lastFetch = new Date().valueOf();
    isFetchStatuses = true;

    getStatuses().finally(() => {
      isFetchStatuses = false;
    });
  };

  const runStatusesLoop = () => {
    if (!refStatusesState.intervalId) {
      refStatusesState.intervalId = setInterval(currentFetchGpdipStatuses, STATUSES_TIMEOUT);
      if (!refStatusesState.lastFetch || new Date().valueOf() - refStatusesState.lastFetch > STATUSES_TIMEOUT) {
        currentFetchGpdipStatuses();
      }
    }
  };

  const stopStatusesLoop = () => {
    if (refStatusesState.intervalId) {
      clearInterval(refStatusesState.intervalId);
      refStatusesState.intervalId = null;
    }
  };

  const onVisibilityChange = () => {
    switch (document.visibilityState) {
      case 'visible':
        runStatusesLoop();
        break;
      case 'hidden':
        stopStatusesLoop();
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    runStatusesLoop();
    return () => {
      stopStatusesLoop();
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return { statuses, isLoading };
};
