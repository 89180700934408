import { selectStudyUserPermissions, selectStudyUserPermissionsReady } from '@modules/study/duck/studySelectors';
import { useSelector } from 'react-redux';

export const useStudyPermissions = () => {
  const userPermissions = useSelector(selectStudyUserPermissions);
  const userPermissionsReady = useSelector(selectStudyUserPermissionsReady);

  return {
    userPermissions,
    userPermissionsReady,
  };
};
