export const getAppConfig = () => ({
  version: '1.0.5',
});

export const configuration = {
  loginURL: '/login-sso',
  logoutURL: '/logout',
  apiUrl: '/',
  defaultInnerSiderWidth: 200,
};

export const CrossStudyId = 0;

export const DataStageTablePrefix = 'DS_';

export const GPDIP_FEATURE = false;
export const DATASET_FEATURE = true;
