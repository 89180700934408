import { Loader } from '@components';
import { useCurrentUserQuery } from '@modules/user/duck/userApi';
import { JSXElementConstructor, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthReady } from './duck/AuthSelector';
import { authActions } from './duck/AuthSlice';

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useDispatch();
  const currentUserQuery = useCurrentUserQuery();

  const authReady = useSelector(selectAuthReady);

  useEffect(() => {
    if (currentUserQuery.isSuccess && !!currentUserQuery.data) {
      localStorage.setItem('user_name', currentUserQuery.data?.preferred_username.split(':').at(0) || '');
      dispatch(authActions.setUser(currentUserQuery.data));
    }
  }, [currentUserQuery, dispatch]);

  return authReady ? children : <Loader mode="fixed" size="large" />;
};

interface AuthProviderProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}
