import { IDatasetListResponse, EDatasetType } from '@modules/dataset/DatasetTypes';
import { Table } from '@ui';
import { ActorAndInfo } from '@components';
import { dateToString } from '@shared/utils/Date';
import { getCreatedByInfo } from '@shared/utils/common';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { DatasetActionButtons } from '@modules/dataset/components/DatasetActionButtons';
import { tableListToOptions } from '@shared/utils/Table';
import React, { useMemo } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';

export const DatasetList = ({ data, studyId, loading, pagination, onChange, t }: DatasetListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<IDatasetListResponse['items'][0]>();

  const datasetTypes = tableListToOptions(
    data?.map((el) => ({ name: t(`rootTable.typeNames.${el.type}`), id: el.type })),
  );

  const columns: TableColumnsType<IDatasetListResponse['items'][0]> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        ...getColumnSearchProps('name'),
        onFilter: undefined,
      },
      {
        title: t('rootTable.type'),
        dataIndex: 'type',
        filters: datasetTypes,
        onFilter: undefined,
        render: (type: EDatasetType) => t(`rootTable.typeNames.${type}`),
      },
      {
        title: t('rootTable.created'),
        dataIndex: 'created_at',
        sorter: () => 0,
        sortDirections: ['ascend'],
        render: (createdAt: number, record) => (
          <ActorAndInfo info={dateToString(createdAt)} actor={getCreatedByInfo(record)} />
        ),
      },
      {
        title: t('rootTable.updated'),
        dataIndex: 'updated_at',
        sorter: () => 0,
        sortDirections: ['ascend'],
        render: (updatedAt: number, record) => (
          <ActorAndInfo info={dateToString(updatedAt)} actor={getCreatedByInfo(record)} />
        ),
      },
      {
        width: 150,
        align: 'center',
        title: t('rootTable.actions.title'),
        dataIndex: '',
        key: 'actions',
        render: (_, record) => <DatasetActionButtons record={record} t={t} studyId={studyId} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datasetTypes, studyId],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface DatasetListProps {
  data?: IDatasetListResponse['items'];
  pagination?: TableProps<IDatasetListResponse['items'][0]>['pagination'];
  onChange?: TableProps<IDatasetListResponse['items'][0]>['onChange'];
  loading?: boolean;
  t: TFunction;
  studyId: number;
}
