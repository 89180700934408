import { appApi } from '@config/appApi';
import { GpdipSynchronization, IGpdip, ISettings, RunExport, RunStatus } from '@modules/gpdip/GpdipTypes';
import { GPDIP_TAG_DESCRIPTION } from '@modules/gpdip/duck/gpdipConstants';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const GpdipApiRoutes = {
  list: 'api/external-sync/data',
  settings: 'api/external-sync/settings',
  run: 'api/external-sync/data/run',
  status: 'api/external-sync/data/run/status',
};

const GpdipInvalidations: {
  LIST: TagDescription<GPDIP_TAG_DESCRIPTION.LIST>;
  SETTINGS: TagDescription<GPDIP_TAG_DESCRIPTION.SETTINGS>;
  STATUSES: TagDescription<GPDIP_TAG_DESCRIPTION.STATUSES>;
} = {
  LIST: { type: GPDIP_TAG_DESCRIPTION.LIST, id: 'LIST' },
  SETTINGS: { type: GPDIP_TAG_DESCRIPTION.SETTINGS, id: 'SETTINGS' },
  STATUSES: { type: GPDIP_TAG_DESCRIPTION.STATUSES, id: 'STATUSES' },
};

export const GpdipApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    gpdipList: builder.query<IGpdip[], void>({
      providesTags: [GpdipInvalidations.LIST],
      query: () => ({
        url: GpdipApiRoutes.list,
      }),
    }),
    gpdipSync: builder.mutation<void, GpdipSynchronization>({
      invalidatesTags: [GpdipInvalidations.LIST],
      query: (data) => ({
        data,
        method: 'PUT',
        url: GpdipApiRoutes.list,
      }),
    }),
    gpdipSettings: builder.query<ISettings, void>({
      providesTags: [GpdipInvalidations.SETTINGS],
      query: () => ({
        url: GpdipApiRoutes.settings,
      }),
    }),
    gpdipSaveSettings: builder.mutation<void, ISettings>({
      invalidatesTags: [GpdipInvalidations.SETTINGS],
      query: (data) => ({
        data,
        method: 'POST',
        url: GpdipApiRoutes.settings,
      }),
    }),
    gpdipRun: builder.mutation<void, RunExport>({
      query: (data) => ({
        data,
        method: 'POST',
        url: GpdipApiRoutes.run,
      }),
    }),
    gpdipRunStatus: builder.query<RunStatus[], void>({
      providesTags: [GpdipInvalidations.STATUSES],
      query: () => ({
        url: GpdipApiRoutes.status,
      }),
    }),
  }),
});

export const {
  useGpdipListQuery,
  useGpdipSyncMutation,
  useGpdipSettingsQuery,
  useGpdipSaveSettingsMutation,
  useLazyGpdipRunStatusQuery,
  useGpdipRunMutation,
} = GpdipApi;
