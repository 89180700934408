import { MODEL_STATUSES_TIMEOUT } from '@modules/model/duck/modelConstants';
import { useLazyRunModelStatusQuery } from '@modules/model/duck/modelApi';
import { RunModelStatus } from '@modules/model/ModelTypes';
import { useEffect, useState } from 'react';
import { isArray } from 'lodash';

export const useModelRunStatusListener = () => {
  const [runModelStatusQuery, { isLoading }] = useLazyRunModelStatusQuery();
  const [statuses, setStatuses] = useState<RunModelStatus[]>([]);
  const refRunModelStatusesState: { intervalId: null | NodeJS.Timer; lastFetch: number | null } = {
    intervalId: null,
    lastFetch: null,
  };

  let isFetchStatuses = false;

  const getStatuses = async () => {
    try {
      const data = await runModelStatusQuery().unwrap();
      setStatuses(isArray(data) ? data : []);
    } catch (err) {
      console.error(err);
    }
  };

  const currentFetchRunModelStatuses = () => {
    if (isFetchStatuses) {
      return;
    }
    refRunModelStatusesState.lastFetch = new Date().valueOf();
    isFetchStatuses = true;

    getStatuses().finally(() => {
      isFetchStatuses = false;
    });
  };

  const runModelStatusesLoop = () => {
    if (!refRunModelStatusesState.intervalId) {
      refRunModelStatusesState.intervalId = setInterval(currentFetchRunModelStatuses, MODEL_STATUSES_TIMEOUT);
      if (
        !refRunModelStatusesState.lastFetch ||
        new Date().valueOf() - refRunModelStatusesState.lastFetch > MODEL_STATUSES_TIMEOUT
      ) {
        currentFetchRunModelStatuses();
      }
    }
  };

  const stopRunModelStatusesLoop = () => {
    if (refRunModelStatusesState.intervalId) {
      clearInterval(refRunModelStatusesState.intervalId);
      refRunModelStatusesState.intervalId = null;
    }
  };

  const onVisibilityChange = () => {
    switch (document.visibilityState) {
      case 'visible':
        runModelStatusesLoop();
        break;
      case 'hidden':
        stopRunModelStatusesLoop();
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    runModelStatusesLoop();
    return () => {
      stopRunModelStatusesLoop();
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return { statuses, isLoading };
};
