import { selectGlobalStudy } from '@app/duck/appSelectors';
import { IAuthStudyRolesItem } from '@modules/auth/AuthTypes';
import { useSetActiveStudyRoleMutation, useStudyRolesQuery } from '@modules/auth/duck/authApi';
import { formatUserPermissions } from '@modules/user/duck/userUtils';
import { Select } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudyActiveUserRole } from '../duck/studySelectors';
import { studyActions } from '../duck/studySlice';

export const StudyRoleSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['study', 'shared']);
  const globalStudy = useSelector(selectGlobalStudy);
  const studyActiveRole = useSelector(selectStudyActiveUserRole);

  const studyRolesQuery = useStudyRolesQuery(globalStudy?.id!, { skip: !globalStudy?.id });
  const [setStudyRole, setStudyRoleQuery] = useSetActiveStudyRoleMutation();

  useEffect(() => {
    if (
      studyRolesQuery.data?.current_active_role &&
      studyActiveRole?.role_id !== studyRolesQuery.data.current_active_role.role_id
    ) {
      dispatch(studyActions.setActiveUserRole(studyRolesQuery.data.current_active_role));
      // const formattedUserPermissions = formatUserPermissions(studyRolesQuery.data.permissions);
      const formattedUserPermissions = formatUserPermissions(studyRolesQuery.data.permissions);
      dispatch(studyActions.setUserPermissions(formattedUserPermissions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, studyRolesQuery.data]);

  const isLoading = studyRolesQuery.isFetching || setStudyRoleQuery.isLoading;

  return (
    <Select
      style={{ order: 999, maxWidth: 200 }}
      placeholder={isLoading ? t('loading') : t('toolbar.noRolesPlaceholder')}
      dropdownMatchSelectWidth={false}
      loading={isLoading}
      value={studyActiveRole?.role_id}
      options={studyRolesQuery.data?.all_roles || []}
      fieldNames={{ label: 'name', value: 'role_id' }}
      onChange={(value, option) => {
        if (value !== studyActiveRole?.role_id) {
          setStudyRole({ role_id: value, study_id: globalStudy?.id! }).then(() => {
            dispatch(studyActions.setActiveUserRole(option as IAuthStudyRolesItem));
          });
        }
      }}
    />
  );
};
